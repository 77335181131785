import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/layout";
import LPFooter from "../../components/LPFooter";
import ReadMoreWithLeadForm from "../../components/ReadMoreWithLeadForm";
import Miniview from "../../templates/MiniView";
import RevealAnimation from "../../components/RevealAnimation";
import TrustedPartner from "../../components/trustedPartner";
import DataService from "../../services/api/data_services";
import { navigate } from "gatsby";
import DigiryteLogo from "../../assets/Images/Homepage/Digiryte-logo.svg";
import AutoSlideImage from "../../components/AutoSlideImage";
import ClientLogos from "../../assets/Images/landing-page/client.jpg";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import JSON_DATA from "./data.json";
const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.26825" width="24" height="24" rx="12" fill="#E89F38" />
      <path
        d="M18 5.86823L17.8364 5.75312L17.8363 5.75327L17.8359 5.75391L17.834 5.75655L17.8265 5.76719L17.7965 5.80952C17.77 5.84695 17.7303 5.9026 17.6783 5.97528C17.5743 6.12065 17.4205 6.33415 17.2221 6.60627C16.8253 7.15052 16.2497 7.92922 15.5354 8.86637C14.1064 10.7409 12.1229 13.2483 9.90367 15.7811C9.37776 16.381 8.90051 16.6 8.49311 16.6261C8.08233 16.6524 7.70105 16.4853 7.36422 16.2294C7.02742 15.9736 6.75049 15.6402 6.55592 15.3658C6.45924 15.2295 6.38428 15.1097 6.33373 15.0244C6.30847 14.9818 6.28938 14.948 6.27678 14.9251L6.26281 14.8994L6.25956 14.8932L6.25889 14.8919L6.25886 14.8919C6.25884 14.8918 6.25882 14.8918 6.25881 14.8918L6.2588 14.8918C6.2192 14.8152 6.13499 14.7726 6.04984 14.7861C5.96465 14.7996 5.89771 14.8662 5.88377 14.9513C5.69619 16.0971 5.8337 16.9783 6.19672 17.6173C6.56149 18.2594 7.14102 18.634 7.78759 18.7867C9.06416 19.0883 10.625 18.5323 11.4303 17.46L11.4304 17.46C12.5341 15.9903 14.2229 13.1104 15.6303 10.6091C16.3354 9.3562 16.9719 8.19455 17.4321 7.34617C17.6622 6.92195 17.8483 6.576 17.9769 6.33607C18.0412 6.2161 18.0911 6.12263 18.125 6.05912L18.1635 5.98677L18.1733 5.96827L18.1758 5.96357L18.1764 5.96238L18.1766 5.96208C18.1766 5.96201 18.1767 5.96197 18 5.86823ZM18 5.86823L17.8365 5.75308C17.8973 5.66675 18.0147 5.64254 18.1047 5.69779C18.1946 5.75304 18.2262 5.86871 18.1767 5.96197L18 5.86823Z"
        fill="white"
        stroke="white"
        strokeWidth="0.4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

function LandingPage() {
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setClientStoryData([...JSON_DATA.data]);
    setClientStoryRawData(JSON_DATA);
  }, [page, limit]);

  const listObject = [
    "Reduce software development costs.",
    "Rigorous testing for high-quality code.",
    "Dedicated project manager for reliable communication.",
    "Full transparency over cost and progress.",
    "Meet tight deadlines consistently.",
  ];

  const options = [
    {
      key: "We don’t have revenue yet, but we have investors",
      id: 1,
    },
    { key: "We have $100K ARR", id: 2 },
    { key: "We have $300K ARR", id: 3 },
    { key: "We have $500K ARR or more", id: 4 },
    { key: "We are new and have no revenue", id: 5 },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.id);
  };

  const handleSubmit = () => {
    if (selectedOption !== 5) {
      window.fbq("track", "softlead");
    }
    // const modifiedText = selectedOption
    //   .replace(/\s+/g, "-") // Replace spaces with hyphens
    //   .replace(/,/g, "") // Remove commas
    //   .replace(/’/g, "") // Remove apostrophes (’)
    //   .toLowerCase(); // Convert to lowercase

    navigate(`/landing-page/offer?${selectedOption}`);
  };

  return (
    <Layout
      seo={{
        title: "Landing Page | Digiryte",
        ogtitle: "Landing Page | Digiryte",
        description:
          "Explore Digiryte's Clutch profile to see our track record of accelerating digital capabilities for startups, scaleups, and enterprises across various industries.",
        keywords:
          "Clutch, Digiryte reviews, digital capabilities, startups, scaleups, enterprises",
      }}
      isLandingPage={true}
      showHeader={false}
      pageUrl={"-landing-page"}
    >
      <GatsbySeo noindex={true} />
      <div className="body-container" style={{ padding: "20px 0" }}>
        <img className="" src={DigiryteLogo} alt="Digiryte" />
      </div>
      <div
        className="lpHeroSection no-after"
        style={{ paddingTop: "0", paddingBottom: "0" }}
      >
        <div className="heroSectionWrap body-container">
          <div className="leftSectionWrapper">
            <RevealAnimation component={"h2"} className="lp-bannerTitle">
              Get an Offshore Team That Delivers Perfectly{" "}
              <span className="lp-banner-highlight">Matched</span> Software in{" "}
              <span className="lp-banner-highlight">30 Days</span> - Or It’s{" "}
              <span className="lp-banner-highlight">Free</span>
            </RevealAnimation>
            <RevealAnimation component={"p"} className={"lp-banner-subTitle"}>
              ONLY for SaaS businesses with +300K ARR or investors on board: We
              reduce your costs and increase your code quality with developers
              handpicked from India’s elite top 3% tech talent.
            </RevealAnimation>
            <RevealAnimation animation="fade" className="list">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  paddingTop: "15px",
                  fontSize: "18px",
                  letterSpacing: "0.03px",
                }}
              >
                {listObject.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CheckIcon />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </RevealAnimation>
          </div>
          <RevealAnimation animation="zoom-in" className="rightSectionWrapper">
            <div className={`form-warper only-form-wrapper`}>
              <div
                className="inner-container only-form-inner-container"
                style={{ flexDirection: "column" }}
              >
                <h2
                  className="title-header lp-form-header3"
                  style={{ textAlign: "center" }}
                >
                  <span className="red-line3letter">
                    Start below to see how it works in detail
                  </span>
                </h2>
                <div className={`form-right-wrap only-form-container`}>
                  <h2 className="title-header lp-form-header2">
                    How much ARR does your business currently generate?
                  </h2>
                  <div className="options-list">
                    {options.map((option, index) => (
                      <div key={index} className="option-item">
                        <button
                          className={`option-button ${
                            selectedOption === option.id ? "selected" : ""
                          }`}
                          onClick={() => handleOptionClick(option)}
                        >
                          {selectedOption === option.id ? (
                            <CheckIcon />
                          ) : (
                            <div className="option-uncheck"></div>
                          )}
                          {option.key}
                        </button>
                      </div>
                    ))}
                  </div>
                  <button
                    className="submit-btn"
                    onClick={handleSubmit}
                    disabled={!selectedOption} // Disable submit if no option is selected
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </RevealAnimation>
        </div>
      </div>
      <div className="mt-50 common-bg-light">
        <div className="body-container">
          <RevealAnimation
            component={"div"}
            animation={"slide-left"}
            className="title-header title-underline"
          >
            Trusted partner for startups and <br />
            Fortune 500 companies
          </RevealAnimation>
        </div>
        <div className="mt-50">
          <AutoSlideImage
            imageUrl={ClientLogos}
            direction="left-to-right"
            customClass="slide-duration-40 mix-darken"
          />
        </div>
      </div>
      {/* <div>
        <TrustedPartner
          showVideo={false}
          title={
            <>
              Trusted partner for startups and <br />
              Fortune 500 companies
            </>
          }
          showAllBtn={false}
          imgContainerClassName="custom-gap"
        />
      </div> */}

      <div className="mt-70">
        <div className="body-container">
          <RevealAnimation component={"h2"} className="title-header ttc">
            <span className="red-line3letter">Cli</span>ent Stories
          </RevealAnimation>
        </div>
        <div className="mt-50 lp-client-review">
          <Miniview
            miniviewData={clientStoryData}
            total={clientStoryData.length}
            isLandingPage={true}
          />
        </div>
        <ReadMoreWithLeadForm
          has_more={clientStoryRawData.has_more}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
          moreClientBtn={false}
        />
      </div>
      <div className="mt-70"></div>
      <LPFooter
        title={
          <>
            Our team's quality is certified by ISO 9001 and ISO 27001 and we
            delivered 119 successful software projects.
          </>
        }
        showCta={false}
        containerClass="custom-container"
      />
    </Layout>
  );
}

export default LandingPage;
